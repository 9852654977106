<template>
  <div class="dynamic-form-wrapper">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: "DynamicFormWrapper",
};
</script>

<style lang="scss" scoped>
.dynamic-form-wrapper {
  background-color: white;
  height: 100%;
  // overflow: auto;
}
</style>